import './social-feed.scss';
import AjaxLoad from './../../utilities/js/ajax-load';
import Swiper from 'swiper/bundle';

class SocialFeed {
    constructor(element) {
        this.$socialSliderRoot = element;
        this.$feedType = this.$socialSliderRoot.dataset.feedType;

        this.$feedWrapper = element.querySelector(
            '[data-social-feed="feed-items"]'
        );

        this.$socialSlider = this.$socialSliderRoot.querySelector(
            '[data-social-feed="feed"]'
        );
        this.slider = [];

        this.initialize();
    }

    initialize() {
        new AjaxLoad(`/api/social-feed?feed=${this.$feedType}`, {
            requestMethod: 'GET',
            onSuccess: (result) => {
                const items = JSON.parse(result);
                if (items) {
                    this.$feedWrapper.innerHTML = (
                        typeof items === 'object' ? Object.values(items) : items
                    )
                        ?.map((item) => this.getItemElement(item))
                        ?.join(' ');
                }

                this.slider = new Swiper(this.$socialSlider, {
                    loop: true,
                    spaceBetween: 24,
                    breakpoints: {
                        0: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            slidesPerGroupSkip: 1,
                            pagination: {
                                type: 'fraction',
                                renderFraction: function renderFraction(
                                    currentClass,
                                    totalClass
                                ) {
                                    return (
                                        '<span class="' +
                                        currentClass +
                                        '"></span>' +
                                        '/' +
                                        '<span class="' +
                                        totalClass +
                                        '"></span>'
                                    );
                                }
                            }
                        },
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                            slidesPerGroupSkip: 2,
                            pagination: {
                                type: 'bullets'
                            }
                        },
                        1120: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            slidesPerGroupSkip: 3,
                            pagination: {
                                type: 'bullets'
                            }
                        }
                    },
                    pagination: {
                        el: '.social-feed__swiper-pagination',
                        clickable: true,
                        renderBullet: function (index, className) {
                            return `<span class="${className}"></span>`;
                        }
                    },
                    initAttr: 'data-social-slider'
                });

                this.setEvents();
            }
        });
    }

    getItemElement(item) {
        const networkName = item.networkName.toLowerCase().trim();
        const networkImage = networkName === 'twitter' ? 'x' : networkName;
        const itemUrl = this.getItemUrl(item);

        const maxPostContentLen = 150;
        let postContent = item.postContent.trim();
        if (postContent.length > maxPostContentLen) {
            postContent = postContent.slice(0, maxPostContentLen) + '...';
        }

        let el = '';
        el += `<div class="social-feed__feed-item swiper-slide" data-network="${item.networkName}">`;

        if (item.postLinkUrl) {
            el += `<a href="${itemUrl}" rel="noreferrer nofollow" target="_blank">`;
        }

        if (item.thumbnail) {
            el += `<div class="social-feed__item-image">
              ${item.thumbnail}
            </div>`;
        } else if (item.image) {
            el += `<div class="social-feed__item-image">
              <img src="${item.image}" alt="Post image" loading="lazy" />
            </div>`;
        }

        el += `<div class="social_feed__item-content-wrapper">
            <div class="social_feed__item-content">${postContent}</div>
            <div class="social_feed__item-network">
                <img src="/dist/assets/images/social/${networkImage}.svg" alt="${networkName}" loading="lazy" height="42" width="42">
            </div>
        </div>`;

        if (item.postLinkUrl) {
            el += `</a>`;
        }

        el += `</div>`;
        return el;
    }

    getItemUrl(item) {
        if (item.networkName.toLowerCase() !== 'twitter') {
            return item.postLinkUrl;
        }

        return `https://twitter.com/rewedigital/status/${item.postLinkUrl}`;
    }

    setEvents() {
        const prevButtons = this.$socialSliderRoot.querySelectorAll(
            '.social-feed__swiper-button-prev'
        );

        if (prevButtons) {
            [].slice.call(prevButtons).forEach((prevButton) => {
                prevButton.addEventListener('click', () => {
                    this.slider.slidePrev();
                });

                prevButton.addEventListener('keydown', (e) => {
                    if (e.which === 13 || e.which === 32) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.slider.slidePrev();
                    }
                });
            });
        }

        const nextButtons = this.$socialSliderRoot.querySelectorAll(
            '.social-feed__swiper-button-next'
        );
        if (nextButtons) {
            [].slice.call(nextButtons).forEach((nextButton) => {
                nextButton.addEventListener('click', () => {
                    this.slider.slideNext();
                });

                nextButton.addEventListener('keydown', (e) => {
                    if (e.which === 13 || e.which === 32) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.slider.slideNext();
                    }
                });
            });
        }
    }
}

export { SocialFeed };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $socialFeeds = $context.querySelectorAll(
            '[data-social-feed="root"]'
        );
        for (let i = 0; i < $socialFeeds.length; i++) {
            $socialFeeds[i].API = new SocialFeed($socialFeeds[i]);
        }
    }
});
